import { Navigate, Route, Routes } from "react-router-dom";

import NavBar from "./components/NavBar";
import TrafficChange from "./components/TrafficChange";
import PopularDomains from "./components/PopularDomains";
import AttackLayer3 from "./components/AttackLayer3";

function App() {
	return <>
		<NavBar />
		<Routes>
			<Route
				path="/"
				element={<TrafficChange />}
			/>
			<Route
				path="/popular-domains"
				element={<PopularDomains />}
			/>
			<Route
				path="/attack-layer3"
				element={<AttackLayer3 />}
			/>
			<Route
				path="*"
				element={<Navigate to="/" />}
			/>
		</Routes>
	</>
}

export default App;
