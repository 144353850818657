import { useEffect, useState } from "react";
import {
	Brush,
	Legend,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from "recharts";

function AttackLayer3() {
	const [data, setData] = useState({} as any);
	const [status, setStatus] = useState("Loading...");

	async function getData() {
		try {
			const response = await fetch("https://general-assignment-2022.shangzhen.workers.dev/attack-layer3");
			if (response.ok) {
				const json = await response.json();
				setData(json);
				setStatus("");
			} else {
				setStatus("HTTP Error: " + response.status);
			}
		} catch (err: any) {
			console.error(err);
			setStatus(err.toString());
		}
	}

	const chartData: any[] = [];
	if (data.data) {
		const length = data.data.total.values.length;
		for (let i = 0; i < length; i++) {
			chartData.push({
				timestamp: new Date(data.data.total.timestamps[i]).toLocaleString(),
				total: Math.round(data.data.total.values[i] * 1000000) / 10000
			});
		}
	}

	useEffect(() => {
		getData();
	}, []);

	return <main>
		<h1>Layer 3 DDoS Attack</h1>
		{status && <p>{status}</p>}
		<div className="chart">
			<ResponsiveContainer height="100%" width="100%">
				<LineChart data={chartData} height={300} width={500}>
					<XAxis dataKey="timestamp" />
					<YAxis />
					<Tooltip content={<CustomTooltip />} />
					<Legend />
					<Line
						type="monotone"
						dataKey="total"
						stroke="#8884d8"
						dot={false}
					/>
					<Brush dataKey="timestamp" height={30} stroke="#8884d8" />
				</LineChart>
			</ResponsiveContainer>
		</div>
	</main>
}

function CustomTooltip({ active, payload }: any) {
	if (!active || !payload || payload.length === 0) {
		return null;
	}

	return <div className="custom-tooltip">
		<div className="description">This chart indicates Layer 3 DDoS Attack over the last 30 Days.</div>
		<div><strong>Time: </strong>{payload[0].payload.timestamp}</div>
		<div><strong>Percentage for the attack: </strong>{payload[0].payload.total + "%"}</div>
	</div>
}

export default AttackLayer3;
