import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong, faUpLong } from "@fortawesome/free-solid-svg-icons";

interface RankingEntry {
	rank: string;
	rankChange: string;
	domain: string;
	category: string;
}

interface PopularDomainsData {
	rankingEntries?: RankingEntry[];
}

function PopularDomains() {
	const [data, setData] = useState({} as PopularDomainsData);
	const [status, setStatus] = useState("Loading...");

	async function getData() {
		try {
			const response = await fetch("https://general-assignment-2022.shangzhen.workers.dev/popular-domains");
			if (response.ok) {
				const json = await response.json();
				setData(json);
				setStatus("");
			} else {
				setStatus("HTTP Error: " + response.status);
			}
		} catch (err: any) {
			console.error(err);
			setStatus(err.toString());
		}
	}

	const rankingEntries = data.rankingEntries ? data.rankingEntries.sort((a, b) => {
		return parseInt(a.rank) - parseInt(b.rank);
	}) : [];

	const tableElem = rankingEntries.map(item => {
		const rankChange = parseInt(item.rankChange);
		let rankChangeElem = null;
		if (rankChange > 0) {
			rankChangeElem = <FontAwesomeIcon
				className="rank-change increased"
				icon={faUpLong}
				title="Rank increased compared to data from the previous 30 day mark."
			/>
		} else if (rankChange < 0) {
			rankChangeElem = <FontAwesomeIcon
				className="rank-change decreased"
				icon={faDownLong}
				title="Rank decreased compared to data from the previous 30 day mark."
			/>
		}
		return <tr key={item.rank}>
			<td>{parseInt(item.rank) + 1}{rankChangeElem}</td>
			<td>{item.domain}</td>
			<td>{item.category}</td>
		</tr>
	});

	useEffect(() => {
		getData();
	}, []);

	return <main>
		<h1>Popular Domains</h1>
		{status && <p>{status}</p>}
		<table>
			<thead>
				<tr>
					<th scope="col">Rank</th>
					<th scope="col">Domain</th>
					<th scope="col">Category</th>
				</tr>
			</thead>
			<tbody>{tableElem}</tbody>
		</table>
	</main>
}

export default PopularDomains;
