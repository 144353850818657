import { NavLink } from "react-router-dom";

function NavBar() {
	const navItems = [{
		path: "/",
		text: "Traffic Change"
	}, {
		path: "/popular-domains",
		text: "Popular Domains"
	}, {
		path: "/attack-layer3",
		text: "Layer 3 DDoS Attack"
	}];

	const navItemsElem = navItems.map(item => {
		return <NavLink
			end
			key={item.path}
			to={item.path}>
			{item.text}
		</NavLink>
	});

	return <nav>{navItemsElem}</nav>
}

export default NavBar;
